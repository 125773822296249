import { Button, Result } from 'antd';

export const Error = () => {
    return (
        <Result
        status="error"
        title="An error occurred"
        subTitle="Please try again later"
        extra={[
            <Button href='/login' type="primary">
                Login
            </Button>,
        ]}
    />
    )
}