import { Button, Result } from 'antd';

export const Success = () => {
    return (
        <Result
        status="success"
        title="Successfully Logged In"
        subTitle="You can now close this tab."
        extra={[
            <Button type="primary" key="console" onClick={() => window.close()}>
                Close tab
            </Button>
        ]}
    />
    )
}