import React, { useEffect, useState } from 'react';
import { Card, Form, Input, Button, Typography, Flex, Avatar, Space, Steps, Tag, theme, Progress, Rate } from 'antd';
import { CopycatSvg } from './Logo';
import Lottie from 'lottie-react';
import SlideClipboards from "../assets/slide-clipboards-dark.json";
import SlideCopy from "../assets/slide-copy-dark.json";
import SlidePaste from "../assets/slide-paste-dark.json";
import SlideWordSelector from "../assets/slide-wordSelector-dark.json";
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
//import './App.css';
const { Title, Text } = Typography;
const { useToken } = theme;

const CustomStep = ({ step }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div>
            <Lottie options={defaultOptions} animationData={step.animationData} height={400} width={400} />
            <Title level={3} style={{marginBottom:2, marginTop:0}}>{step.title}</Title>
            <>{step.description}</>
        </div>
    );
}

export const Onboarding = () => {
    const { Title, Text } = Typography
    const { Step } = Steps;
    const [currentStep, setCurrentStep] = useState(0);
    const [rate, setRate] = useState(null);
    const { token } = useToken();


    useEffect(() => {
      if (currentStep === steps.length - 1) {
        fetch('/api/onboarding-complete', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      }
    }, [currentStep]);

    useEffect(() => {
      if (rate !== null) {
        fetch('/api/onboarding-rate', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ onboardingRate: rate }),
        })
      }
    }, [rate]);

    const steps = [
        {
          title: 'Work with shortcuts',
          description: (
            <p>Each clipboard the same shortcut that works both to copy and paste:<br/>
            <Tag color='blue'>Alt</Tag>+ <Tag color='blue'>1</Tag> for clipboard 1,<br/>
            <Tag color='success'>Alt</Tag>+<Tag color='success' >2</Tag> for clipboard 2,<br/>
            <Tag color='warning'>Alt</Tag>+ <Tag color='warning'>3</Tag> for clipboard 3.<br/>
            </p>
          ),
          animationData: SlideClipboards,
        },
        {
          title: 'Copy text',
          description: (
            <p>Select text and enter a clipboard shortcut to copy it.</p>          ),
          animationData: SlideCopy,
        },
        {
            title: 'Paste text',
            description: (
                <p>Click inside of a text field and enter a clipboard shortcut. <br/> The text copied in the related clipboard will be pasted.</p>
            ),
            animationData: SlidePaste,
        },
        {
          title: 'Select words quickly',
          description: (
            <p>Enter <Tag color='purple'>Alt</Tag>+ <Tag color='purple'>4</Tag>to enable Word Selector. <br/>
            This feature catches the closest word to the mouse without having to manually select it.</p>
          ),
          animationData: SlideWordSelector,
        },
        {
          title: 'You\'re all set!',
          description: (
            <p>How did you find the tutorial?</p>
          ),
          },
      ];

    const nextStep = () => {
        setCurrentStep(prevStep => prevStep + 1);
    }

    const prevStep = () => {
        setCurrentStep(prevStep => prevStep - 1);
    }


    return (
      <Flex vertical gap={24}>
        <CustomStep step={steps[currentStep]}/>
        <Flex  vertical align='center' style={{width: "100%"}} gap={20}>
          {currentStep !== steps.length - 1 ?
            <Button size='large' type="default" onClick={nextStep} block active>
              Next
            </Button>
            :
            <Flex gap={20} justify='center' align='center'>
            <Button icon={<LikeOutlined />} size='large' type="default" onClick={() => setRate(1)} style={rate === 1 && {color: token.colorPrimary, borderColor: token.colorPrimary}}>Clear</Button>
            <Button icon={<DislikeOutlined />} size='large' type="default" onClick={() => setRate(0)} style={rate === 0 && {color: token.colorError, borderColor: token.colorError}}>Not clear</Button>
            </Flex>

          }
          {/*<Progress percent={(currentStep / (steps.length - 1)) * 100} showInfo={false} style={{width: "20%"}}/>*/}
        </Flex>
      </Flex>
    )
}