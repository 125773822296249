import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Success } from './components/Success';
import { Error } from './components/Error';
import { CopycatSvg } from './components/Logo';
import './styles/App.css';
import { Login } from './components/Login';
import { Onboarding } from './components/Onboarding';
import { Card, ConfigProvider, theme } from 'antd';
import { Feedback } from './components/Feedback';
import { NotFound } from './components/NotFound';
import { PrivacyPolicy } from './components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
      <div className="header">
          <CopycatSvg size={24} />
          <h1>Copycat</h1>
        </div>
          <ConfigProvider
            theme={{
              algorithm: theme.darkAlgorithm,

              components: {
                Tag: {
                  marginMD: "40px",
                },
                Button: {
                  defaultBg: "#333",
                  
                },
              }
            }}
          >
          <Card bordered 
          style={{ 
            maxWidth: 440,
            borderRadius: 20,
            boxShadow:"rgba(255, 255, 255, 0.15) 0px 48px 100px 0px",
            padding: "20px 16px",
          }}>
            <Routes>
              <Route path="/onboarding" Component={Onboarding}/>
              <Route path="/login" Component={Login}/>
              <Route path='/success' Component={Success} />
              <Route path='/error' Component={Error} />
              <Route path='/feedback' Component={Feedback} />
              <Route path="/privacy" Component={PrivacyPolicy} />
              <Route path="*" Component={NotFound} />
            </Routes>
          </Card>
          </ConfigProvider>
      </div>
    </Router>
  );
}

export default App;