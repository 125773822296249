import React, { useState } from 'react';
import { Card, Form, Input, Button, Typography, Flex, Avatar, Space, Result, theme } from 'antd';
import { CopycatSvg } from './Logo';
//import './App.css'; // Import your CSS file here
import { GoogleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const Login = () => {
    const { Title, Text } = Typography
    const [loginStatus, setLoginStatus] = useState(null);

    // Assuming you have a function to handle Google login
    const handleGoogleLogin = async () => {
        setLoginStatus('processing');
        const oauthUrl = '/auth/google'; // Replace with your actual API endpoint
        window.open(oauthUrl);
    }

    return (
                <Flex vertical align="center" gap={64}>
                    <Avatar
                        shape='square'
                        size={64} 
                        style={{
                            //background: "rgba(22,119,255,1)",
                            background: "rgb(20,20,20)",
                            border: "1px solid rgb(48,48,48)",
                            backdropFilter: "blur(10px)",
                            position: "absolute", 
                            top: "-24px", // Half of the Avatar size to position it above the Card
                            left: "50%", // Center the Avatar
                            transform: "translateX(-50%)", // Ensure it's centered regardless of its width
                            borderRadius: "20px",
                            //boxShadow: "rgba(22, 119, 255, 0.5) 0px 0px 20px 8px",
                        }} 
                        icon={<CopycatSvg size={32}/>}
                    />
                    <Flex vertical>
                    <Title level={2}>Get started with <span style={{color: "rgba(22,119,255,1)"}} >Copycat</span></Title>
                    <Text>Let's connect to your Google account to get started.</Text>
                    </Flex>

                    <Flex vertical gap={8}>
                    <Button 
                    icon={<GoogleOutlined/>} 
                    size='large' 
                    block
                    loading={loginStatus === 'processing'}
                    href='/api/google'
                    //href='/onboarding' 
                    >
                    Connect with Google
                    </Button>
                    <Text type="secondary" style={{fontSize:12}}>By signing up, you confirm that you agree to our <Link to="/privacy">Privacy Policy</Link>.</Text>
                    </Flex>
                </Flex>
    )
}