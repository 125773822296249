export const PrivacyPolicy = () => {
    return (
        <div style={{ overflow: 'auto', height:600 }}>
            <h1>Privacy Policy for Copycat</h1>
            <p>This Privacy Policy outlines how Copycat, (hereafter referred to as "we," "us," or "our"), collects, uses, and protects your information when you use our services.</p>
            
            <h3>Company information:</h3>

            <ul>
                <li>Company Name: Henriot</li>
                <li>Registration Authority: Registered with the RCS of Agen</li>
                <li>SIREN: 903 152 320</li>
                <li>Registered Office: Lieu-dit Roques, 1920 Route du Mas d'Agenais, 47700 Casteljaloux, France</li>
                <li>Email: contact@copycat-extension.com</li>
            </ul>
            
            <h2>1. Information We Collect</h2>
            <p>When you use Copycat, we collect the following information from your Google account after you authorize us:</p>
            <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Profile Picture</li>
                <li>Email Status (Verified or Not Verified)</li>
            </ul>
            <p>This information is collected and processed automatically as part of the signup process when you authorize Copycat to access your Google account.</p>
            <h2>2. Use of Your Information</h2>
            <p>The information we collect is used to:</p>
            <ul>
                <li>Provide you with Copycat's functionalities, including the ability to use three separated clipboards for faster text copying and pasting.</li>
                <li>Send personalized emails to improve your experience with Copycat.</li>
            </ul>
            <h2>3. Sharing of Your Information</h2>
            <p>We do not share your information with third parties.</p>
            <h2>4. Consent and Control</h2>
            <p>By signing up and using Copycat, you consent to this Privacy Policy and to Google sharing your profile information with us. You have the right to access, modify, or request deletion of your data at any time by contacting us at data@copycat-extension.com.</p>
            <h2>5. Data Security</h2>
            <p>We reserve the right to suspend access to our services if unauthorized use is detected as part of our efforts to ensure the security of your data and our services.</p>
            <h2>6. Age Restrictions</h2>
            <p>There are no age restrictions for using Copycat.</p>
            <h2>7. International Users and GDPR</h2>
            <p>For users based in Europe, the General Data Protection Regulation (GDPR) applies. You have specific rights under GDPR, including the right to access, rectify, erase, restrict, or object to the processing of your personal data.</p>
            <h2>8. Changes to This Privacy Policy</h2>
            <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy.</p>
            <h2>9. Contact Information</h2>
            <p>For any privacy-related questions or concerns, please contact us at contact@copycat-extension.com.</p>
        </div>
    );
};