import { Button, Checkbox, Form, Input, Select, Typography, message, Result } from 'antd';
import { useState } from 'react';

export const Feedback = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', values);
        fetch('/log/feedback', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then(response => response.status === 200 ? setFormSubmitted(true): message.error('Error receiving feedback'))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (formSubmitted) {
        return <Result status="success" title="Your feedback has been submitted successfully!" extra={
            <Button type="primary" key="console" onClick={() => setFormSubmitted(false)}>
                Submit another feedback
            </Button>
        
        } />;
    }

    return (
        <Form
        name="basic"
        initialValues={{
        remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout='vertical'
        requiredMark={false}
        style={{
            width: 360,
        }}
        >

            <Typography.Title level={3}>Send <span>Feedback</span></Typography.Title>

            <Form.Item
            label={<Typography.Title style={{marginTop:2, marginBottom:1}} level={5}>Issue Type</Typography.Title>}
            name="type"
            rules={[
                {
                required: true,
                message: 'Please select a type of issue',
                },
            ]}
            >
                <Select
                labelInValue
                placeholder="Bug, Feedback, Feature request"
                options={[
                    { value: 'bug', label: 'Bug' },
                    { value: 'feedback', label: 'Feedback' },
                    { value: 'feature', label: 'Feature request' },
                  ]}
                />
            </Form.Item>

            <Form.Item
            label={<Typography.Title style={{marginTop:2, marginBottom:1}} level={5}>Email</Typography.Title>}
            name="email"
            rules={[
                {
                required: true,
                message: 'Please input your email',
                },
            ]}
            >
                <Input placeholder='john@microsoft.com'/>
            </Form.Item>

            <Form.Item
            label={<Typography.Title style={{marginTop:2, marginBottom:1}} level={5}>Details</Typography.Title>}
            name="details"
            rules={[
                {
                required: true,
                message: 'Please input details about your feedback',
                },
            ]}
            >

            <Input.TextArea placeholder='It would be nice to have...'/>
            </Form.Item>

            <Form.Item>
                <Button type="default" htmlType="submit" size='large' block>
                    Submit
                </Button>
            </Form.Item>
    </Form>
    )
}